import styled from "styled-components";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { SetStateAction, useState } from "react";
import { setCookie } from "../Cookie";
import { ModalBackD } from "../components/Style";
import { device } from "../hooks/device";

interface ReportNoticePopupProps {
  setReportNotice: React.Dispatch<SetStateAction<boolean>>;
}

const ReportNoticePopup: React.FC<ReportNoticePopupProps> = ({
  setReportNotice,
}) => {
  const [notToday, setNotToday] = useState(false);

  const confirmNotice = () => {
    if (notToday) {
      setCookie("reportNoticePopUp", true);
    }
    setReportNotice(false);
  };

  useScrollLockThree();

  return (
    <>
      <ModalBackD modalOff={false} />
      <StNotice>
        <div className="noticeUp">
          <div className="textField">
            <span>
              ProctorMatic identifies potential cases of cheating;
              however,&nbsp;
            </span>
            <span className="highlight">
              the final decision rests with the exam organizers.
            </span>
            <span>
              Therefore, please be sure to review the detailed reports for each
              test-taker before making your judgment.
            </span>
          </div>
          <div className="buttonField" onClick={confirmNotice}>
            OK
          </div>
        </div>
        <div className="noticeDown">
          <input
            type="checkbox"
            className="checkBox"
            width={16}
            height={16}
            onChange={() => setNotToday(!notToday)}
          />
          <span>Don't show again today</span>
        </div>
      </StNotice>
    </>
  );
};

const StNotice = styled.div`
  position: fixed;
  background-color: white;
  z-index: 54;
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-radius: 12px;
  box-sizing: border-box;
  @media ${device.pc} {
    width: 379px;
    padding: 24px 32px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.08);
    .noticeUp {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      .textField {
        font-size: 18px;
        font-family: "semiBold";
        line-height: 27px;
        .highlight {
          color: #c83b38;
          font-family: "bold";
        }
      }
      .buttonField {
        display: flex;
        width: 170px;
        height: 40px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 999px;
        border: 1px solid #20315b;
        box-sizing: border-box;
        color: #20315b;
        font-family: "medium";
        cursor: pointer;
      }
    }
    .noticeDown {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #888888;
      font-size: 12px;
      font-weight: "medium";
      .checkBox {
        width: 16px;
        height: 16px;
        border: 1.5px solid #d7d7d7;
        border-radius: 2px;
      }
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    width: 340px;
    padding: 24px 32px;
    box-sizing: border-box;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    /* border-radius: 26px; */
    .noticeUp {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      .textField {
        font-family: "medium";
        line-height: 24px;
        .highlight {
          color: #c83b38;
          font-family: "bold";
        }
      }
      .buttonField {
        display: flex;
        width: 170px;
        height: 40px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 999px;
        border: 1px solid #20315b;
        box-sizing: border-box;
        color: #20315b;
        font-family: "medium";
        cursor: pointer;
      }
    }
    .noticeDown {
      display: flex;
      justify-content: center;
      gap: 8px;
      color: #888888;
      font-size: 12px;
      align-items: center;
      font-weight: "medium";
      .checkBox {
        width: 16px;
        height: 16px;
        border: 1.5px solid #d7d7d7;
        border-radius: 2px;
      }
    }
  }
`;

export default ReportNoticePopup;
